import React from "react"
import Helmet from "react-helmet"

import chicken1 from "../images/carousel/c1.jpg"
import chicken2 from "../images/carousel/c2.jpg"
import chicken3 from "../images/carousel/c3.jpg"
import chicken4 from "../images/carousel/c4.jpg"
import chicken5 from "../images/carousel/c5.jpg"

const JsonLd = ({ title, url, phone, streetAddress, cityStateZip }) => {
  const cityStateZipArr = cityStateZip.split(" ")
  const city = cityStateZipArr[0].replace(/,/g, "")
  const state = cityStateZipArr[1].replace(/,/g, "")
  const zip = cityStateZipArr[2].replace(/,/g, "")

  const images = [
    `${url}${chicken1}`,
    `${url}${chicken2}`,
    `${url}${chicken3}`,
    `${url}${chicken4}`,
    `${url}${chicken5}`,
  ]

  const schema = [
    {
      "@context": "https://schema.org",
      "@type": "Restaurant",
      "@id": url,
      name: title,
      image: images,
      servesCuisine: ["American cuisine", "Korean cuisine"],
      address: {
        "@type": "PostalAddress",
        streetAddress,
        addressLocality: city,
        addressRegion: state,
        postalCode: zip,
        addressCountry: "US",
      },
      url,
      telephone: phone,
      priceRange: "$$",
      menu: `${url}/#menu`,
      openingHoursSpecification: [
        {
          "@type": "OpeningHoursSpecification",
          dayOfWeek: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ],
          opens: "11:00",
          closes: "21:30",
        },
      ],
    },
  ]

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

export default JsonLd
